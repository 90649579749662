import React, { useEffect, useState } from 'react';
import { 
    Grid, 
    Typography, 
    Divider, 
    useTheme, 
    CircularProgress, 
    Button, 
    Avatar,
    Grow
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import consts from '../../consts/consts';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from '../Dialog/Dialog';
// Helpers
import { convertDateToMexicoCityTimezone } from '../../helpers/functions';

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    left: 'calc(50% - 15px)',
}));

export default function MyClasses(props) {
    const [currentTime, setCurrentTime] = useState(convertDateToMexicoCityTimezone(new Date()));
    const [myClasses, setMyClasses] = useState([]);
    const [isPM, setIsPM] = useState(false);
    const [loading, setLoading] = useState(true);

    // Update every time logguedInUser state changes
    useEffect(() => {
        if (props.logguedInUser) {
            getMyClasses();
            window.scrollTo(0, 0);
        }
    }, [props.logguedInUser]);

    // Update every time schedulesView state changes
    useEffect(() => {
        if (props.schedulesView === 'myClasses' && props.logguedInUser) {
            getMyClasses();
        }
    }, [props.schedulesView]);

    // Update time every second
    useEffect(() => {
        // Initial time update to avoid delay
        let mxTime = convertDateToMexicoCityTimezone(new Date());
        setCurrentTime(mxTime);
        setIsPM(currentTime.getHours() >= 12);
        // Update time every second
        const timer = setInterval(() => {
            let mxTime = convertDateToMexicoCityTimezone(new Date());
            setCurrentTime(mxTime);
            setIsPM(currentTime.getHours() >= 12);
        }, 10000); // Update every 10 seconds

        return () => {
            clearInterval(timer); // Clean up the timer when the component unmounts
        };
    }, []);

    // Obtain classes where logged in user is participant and day is today
    const getMyClasses = async () => {
        // Set loading
        setLoading(true);
        // Obtain today's Date in ISO format
        let today = convertDateToMexicoCityTimezone(new Date());
        today.setHours(0, 0, 0, 0);
        // Request to DB
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/findbyParticipant`, { "participantID" : props.logguedInUser._id, "date" : today })
        .then((res) => {
            // Filter invitees from current user before setting state
            let data = res.data.result;
            let filteredData = data.map((classData) => {
                // Check if there are invitees
                if (classData.invitees.length >= 0) {
                    let invitees = classData.invitees.filter(invitee => ((invitee != null || invitee != undefined) ? invitee.host : '') == props.logguedInUser._id);
                    return {...classData, invitees: invitees};
                } else {
                    return classData;
                }
            });

            setMyClasses(filteredData);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    // Theme
    const theme = useTheme();

    // Get day name
    const getDayName = (day) => {
        const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        return dayNames[day];
    };

    // Get month name
    const getMonth = (month) => {
        const monthNames = consts.shortMonths
        return monthNames[month];
    };

       // Calculate minutes duration of a class -- both variables are in ISO format
    const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Discard the date part and only consider the time
        start.setFullYear(1970, 0, 1);
        end.setFullYear(1970, 0, 1);

        const diff = Math.abs(end - start);
        const minutes = Math.ceil((diff / 1000) / 60);
        return `${minutes} min`;
    };

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertDateToMexicoCityTimezone(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // Class card
    const ClassCard = (props) => {
        const [coachName, setCoachName] = useState({name: '', lastName: ''});
        const [coachPicture, setCoachPicture] = useState('');
        const [touchStart, setTouchStart] = useState(null);
        const [touchEnd, setTouchEnd] = useState(null);
        const [deletionMode, setDeletionMode] = useState(false);
        const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
        const [refundable, setRefundable] = useState(false);

        const minSwipe = 100;
        
        const onTouchStart = (e) => {
            setTouchEnd(null);
            setTouchStart(e.touches[0].clientX);
        }

        const onTouchMove = (e) => setTouchEnd(e.touches[0].clientX);

        const onTouchEnd = () => {
            if (!touchStart || !touchEnd) return
            const distance = touchStart - touchEnd;
            const isLeftSwipe = distance > minSwipe;
            const isRightSwipe = distance < -minSwipe;
            if (isLeftSwipe || isRightSwipe) {
                setDeletionMode(isLeftSwipe);
            }
        }

        useEffect(() => {
            initialSetup();
        }, []);

        let initialSetup = async () => {
            let coach = await props.coaches.find(coach => coach._id == props.classData.teacherID);
            (coach) && await setCoachName({name: coach.firstName, lastName: coach.lastName});
            (coach) && await setCoachPicture(coach.pictureURL);
        }

        // Handle Left button of dialog
        const handleDialogRightButton = () => {
            setDialogProps(
                { 
                    title: '', 
                    content: '', 
                    leftButton: '', 
                    rightButton: '', 
                    open: false
                }
            );
            // Remove class from user
            axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/removeClassParticipants`, 
                { "removeParticipant" : props.logguedInUser._id, "classID" : props.classData._id })
            .then((res) => {
                // If refundable, refund user
                if (refundable) {
                    axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/updateUserCredits`, 
                        { "ID" : props.logguedInUser._id, "operation" : 'add', "creditsType": 'creditsAll', "credits": 1 })
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                } else {
                    getMyClasses();
                }
            })
            .catch((err) => {
                console.log(err);
            });

        }

        // Handle Left button of dialog
        const handleDialogLeftButton = () => {
            setDialogProps(
                { 
                    title: '', 
                    content: '', 
                    leftButton: '', 
                    rightButton: '', 
                    open: false
                }
            );
        }

        return (
            <>
                <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogRightButton={handleDialogRightButton} handleDialogLeftButton={handleDialogLeftButton}/>
                <Grid 
                    container 
                    direction='column' 
                    sx={{
                        backgroundColor: props.color,
                        borderRadius: '30px',
                        left: 3,
                        marginLeft: deletionMode ? '-150px' : '0px',
                        display: 'inline-block',
                        transition: 'all 0.35s',
                        overflow: 'hidden',
                        zIndex: 1,
                        pb: 1
                    }}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <Grid item xs={8} sx={{p:1, width: '100%'}}>
                        <Grid container direction='row'>
                            <Grid item xs textAlign={'initial'} sx={{fontSize: 25, fontFamily: 'PangramBold', fontWeight: 'bold', color: '#000', opacity: 0.60}}>
                                {props.classData.classTitle}
                            </Grid>
                            <Grid item xs textAlign={'end'}>
                                <Grid container direction='row' spacing={1}>
                                    <Grid item xs={6} sm={8} md={10}>
                                        <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', fontWeight: 'bold', color: '#000', opacity: 0.60, lineBreak: ''}}>{`${coachName.name} ${coachName.lastName}`}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={2}>
                                        {coachPicture ? 
                                        <img src={coachPicture} alt='coach' style={{width: 70, height: 70, objectFit: 'cover', borderRadius: '50%'}}/> : 
                                        <Avatar 
                                        sx={{ 
                                            bgcolor: '#000',
                                            opacity: 0.40, 
                                            display: 'inline-flex', 
                                            height: 70, 
                                            width: 70,
                                            fontSize: 45,
                                            fontFamily: 'Qairo',
                                            margin: 1
                                        }}>
                                            {String(coachName.name.charAt(0)).toLocaleUpperCase() + String(coachName.lastName.charAt(0)).toLocaleUpperCase()}
                                        </Avatar>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sx={{p:1, pt: 0, pb: 0}}>
                        <Grid container direction='row'>
                            <Grid item xs textAlign={'initial'}>
                                <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                    {getFormattedTime(props.classData.time)}
                                </Typography>
                                <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                    {'Inicio'}
                                </Typography>
                            </Grid>
                            <Grid item xs textAlign={'center'} alignContent={'center'}>
                                <Typography 
                                    sx={{
                                        fontSize: 20, 
                                        fontFamily: 'PangramBold', 
                                        color: props.color, 
                                        opacity: 0.60, 
                                        borderRadius: '50px',
                                        padding: 0.5,
                                        backgroundColor: 'rgb(0, 0, 0, 0.7)'
                                    }}>
                                    {calculateDuration(props.classData.time, props.classData.endTime)}
                                </Typography>
                            </Grid>
                            <Grid item xs textAlign={'end'}>
                                <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                    {getFormattedTime(props.classData.endTime)}
                                </Typography>
                                <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                    {'Fin'}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Invitees */}
                        {
                            props.classData.invitees.length > 0 &&
                            <Grid item xs textAlign={'left'}>
                                <Divider sx={{m:1}}/>
                                <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                    {`Invitados`}
                                </Typography>
                                    {
                                        props.classData.invitees.map((invitee, index) => {
                                            return (
                                                <li key={`li-${index}`} style={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                                    {`${invitee.firstName} ${invitee.lastName}`}
                                                </li>
                                            )
                                        })
                                    }
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    props.classData.status === 'scheduled' &&
                    <Button
                        sx={{
                            width: deletionMode ? '140px' : '0px',
                            height: deletionMode ? '170px' : '0px',
                            padding: deletionMode ? 'unset' : '0px',
                            opacity: deletionMode ? 1 : 0,
                            borderRadius: '30px',
                            marginTop: '-160px',
                            marginLeft: '10px',
                            backgroundColor: 'red',
                            display: 'inline-block',
                            transition: 'all 0.5s, opacity 1.5s',
                            zIndex: 0,
                            textTransform: 'none'
                        }}
                        onClick={() => {
                            // Minutes difference limit
                            let maxMinutes = 480;

                            // Obtain date and time of class and current date and time
                            let classDay = convertDateToMexicoCityTimezone(new Date(props.classData.date));
                            let classDayTime = convertDateToMexicoCityTimezone(new Date(classDay.getFullYear(), classDay.getMonth(), classDay.getDate(), new Date(props.classData.time).getHours(), new Date(props.classData.time).getMinutes()))
                            let currentDayTime = convertDateToMexicoCityTimezone(new Date());

                            // Define difference in milliseconds
                            let differenceMilliseconds = Math.abs(currentDayTime - classDayTime);
                            let differenceMinutes = Math.floor(differenceMilliseconds / (1000 * 60));

                            // Evaluate if class is refundable
                            (differenceMinutes > maxMinutes) ? setRefundable(true) : setRefundable(false);

                            // Set dialog
                            setDialogProps({
                                title: 'Cancelar clase', 
                                content: (differenceMinutes > maxMinutes) ?
                                '¿Estás segur@ que deseas cancelar esta clase? Se te reembolsará tu crédito a tu Aura card. 😊' :
                                'Lo sentimos, no puedes cancelar esta clase. Solo puedes cancelar clases con 8 horas de anticipación para recibir tu crédito de vuelta, si no puedes asistir no te preocupes, simplemente falta a la case, estamos trabajando para brindarte una mejor experiencia.',
                                leftButton: (differenceMinutes > maxMinutes) ? 'No' : 'Ok', 
                                rightButton: (differenceMinutes > maxMinutes) ? 'Si' : '', 
                                open: true
                            });
                        }}
                    >
                        <CIcon 
                            icon={icon.cilXCircle} 
                            className='text-primary' 
                            style={{height: '50px', color: '#FFF'}}
                        />
                        <Typography sx={{color: '#FFF', fontFamily: 'PangramBold', fontSize: 20, padding: 1}}>Cancelar</Typography>
                    </Button>
                }
            </>
        );
    }

    // Return the component
    return (
        <>
            <Grid container direction='column'>
                {/* Today's day view */}
                <Grid item xs={5} sx={{p: 2}}>
                    <Grid container direction='row'>
                        <Grid item xs={6}>
                            <Grid container direction='column'>
                                <Grid item xs={1} textAlign={'initial'}>
                                    <Typography sx={{ fontSize: 30, lineHeight: 1.5, fontFamily: 'Qairo', fontWeight: 'bold' }}>{getDayName(currentTime.getDay())}</Typography>
                                </Grid>
                                <Grid item xs={11} textAlign={'initial'}>
                                    <Typography sx={{ fontSize: 80, lineHeight: 0.8, fontFamily: 'PangramBold' }}>{`${currentTime.getDate()}.${currentTime.getMonth() + 1}`}</Typography>
                                    <Typography sx={{ fontSize: 80, lineHeight: 1, fontFamily: 'PangramRegular' }}>{`${getMonth(currentTime.getMonth())}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} alignContent={'center'}>
                            <Divider orientation='vertical' sx={{height: '60%', borderColor: theme.palette.text.primary}}/>
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                        <Grid item xs={4} alignContent={'center'} textAlign={'right'}>
                            <Typography sx={{ fontSize: 25, lineHeight: 1 }}>{`${isPM ? (currentTime.getHours() === 12 ? 12 : currentTime.getHours() - 12) : currentTime.getHours()}:${currentTime.getMinutes().toString().padStart(2, '0')} ${(isPM ? 'PM': 'AM')}`}</Typography>
                            <Typography sx={{ marginTop: 1, fontSize: 15, lineHeight: 1 , wordWrap: 'break-word' }}>{`America/Mexico_City`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Today's classes stack */}
                <Grid 
                    item 
                    xs={7} 
                    sx={{
                        backgroundColor: theme.palette.background.paper, 
                        pt: 0.5, 
                        pb: 20,
                        borderTopLeftRadius: '30px', 
                        borderTopRightRadius: '30px',
                    }} 
                    textAlign={'left'}
                >
                    <Puller />
                    <Typography sx={{m: 1, fontFamily: 'PangramBold'}}>Mis clases de hoy</Typography>
                    {
                        loading ?
                        <CircularProgress />
                        :
                        <>
                        {myClasses.map((classData, index) => (
                            <Grow in={true} style={{ transitionDelay: `${50 * index}ms` }} key={`myclasses-grow-${index}`}>
                                <Grid item xs={12} sx={{m:0.75, overflow: 'hidden'}}>
                                    <ClassCard 
                                        coaches={props.coaches} 
                                        classData={classData} 
                                        color={index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main} 
                                        logguedInUser={props.logguedInUser}
                                    />
                                </Grid>
                            </Grow>
                        ))}
                        {
                            myClasses.length === 0 && 
                            <Grid textAlign={'center'}>
                                <Typography 
                                    sx={{
                                        m: 2, 
                                        fontFamily: 'PangramRegular',
                                        width: '85%',
                                        textAlign: 'center',
                                    }}
                                >No tienes clases programadas para hoy, ¿nos unimos a una? ☺️</Typography>
                                <Button 
                                    variant='contained'
                                    color='primary'
                                    sx={{
                                        borderRadius: '30px',
                                        p: 0.75,
                                        textTransform: 'none',
                                        color: '#FFF',
                                        fontSize: 15,
                                        mb: '30vh'
                                    }}
                                    onClick={() => props.handleNewClass()}
                                >Unirme a una clase</Button>
                            </Grid>
                        }
                        </>
                    }
                </Grid> 
            </Grid>
        </>
    );
}
