import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { colors } from '../../themes/themeCons.js';
import AuraLogo from '../../assets/images/aura_icon.svg'

export default function AuraCard (props) {
    useEffect(() => {
        setTimeout(() => {
            flipCard();
        }, 250);
    }, []);

    // Flip card
    const flipCard = () => {
        const card = document.querySelector('.flip-card-inner');
        card.classList.add('flip-card-initial');
        setTimeout(() => {
            card.classList.remove('flip-card-initial');
        }, 1500);
    }

    // Handle touch on card
    const flipCardOnce = () => {
        const card = document.querySelector('.flip-card-inner');
        card.classList.toggle('flip-card-initial');
    }

    return (
        <div className='flip-card'>
            <Box
                sx={{
                    width: '350px',
                    height: '200px',
                }}
                className='flip-card-inner'
                onClick={() => flipCardOnce()}
            >
                <Paper 
                    className='flip-card-front'
                    sx={{
                        backgroundImage: `linear-gradient(to right, ${colors.fourthary}, ${colors.secondary})`, 
                        borderRadius: '15px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    }}>
                    <Grid container direction='column' sx={{p: 1}}>
                        {/* Card header */}
                        <Grid item xs sx={{height: '30px'}}>
                            <Grid container direction='row'>
                                <Grid item xs textAlign={'initial'}>
                                    <Typography sx={{fontFamily: 'Qairo', fontSize: '25px', color: '#000', opacity: 0.6}}>Aura Card</Typography>
                                </Grid>
                                <Grid item xs textAlign={'end'}>
                                    <img height={'30px'} style={{filter: 'brightness(150%) contrast(101%)'}} src={AuraLogo} alt='Aura Logo' />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Card balance */}
                        <Grid item xs textAlign={'left'} sx={{marginTop: '10px'}}>
                            <Typography sx={{fontFamily: 'PangramRegular', lineHeight: 1.5, color: '#000', opacity: 0.6, fontSize: '16px'}}>Clases para mi</Typography>
                            <Grid container direction='row' spacing={0.4}>
                                <Grid item textAlign={'center'}>
                                    {/* <Typography sx={{fontFamily: 'PangramRegular', lineHeight: 1.5, color: '#000', opacity: 0.6, fontSize: '14px', minHeight: '40px', alignContent: 'center'}}>Cualquiera</Typography> */}
                                    <Typography 
                                        sx={{
                                            fontFamily: 'Cutive Mono',
                                            fontWeight: 'bold', 
                                            fontSize: '30px', 
                                            lineHeight: 1, 
                                            backgroundColor: props.darkMode ? '#000' : '#FFF', 
                                            opacity: 0.6, 
                                            display: 'inline-block',
                                            borderRadius: '30px',
                                            padding: 0.5,
                                            m: 0.5
                                        }}>{props.logguedInUser.creditsAll}</Typography>
                                </Grid>
                                {/* <Grid item xs={4} textAlign={'center'}>
                                    <Typography sx={{fontFamily: 'PangramRegular', lineHeight: 1.5, color: '#000', opacity: 0.6, fontSize: '14px', minHeight: '40px', alignContent: 'center'}}>Sculpt & Tone</Typography>
                                    <Typography 
                                        sx={{
                                            fontFamily: 'Cutive Mono',
                                            fontWeight: 'bold', 
                                            fontSize: '30px', 
                                            lineHeight: 1, 
                                            backgroundColor: props.darkMode ? '#000' : '#FFF', 
                                            opacity: 0.6, 
                                            display: 'inline-block',
                                            borderRadius: '30px',
                                            padding: 0.5
                                        }}>{props.logguedInUser.creditsSculpt}</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign={'center'}>
                                    <Typography sx={{fontFamily: 'PangramRegular', lineHeight: 1.5, color: '#000', opacity: 0.6, fontSize: '14px', minHeight: '40px', alignContent: 'center'}}>Pilates Reformer</Typography>
                                    <Typography 
                                        sx={{
                                            fontFamily: 'Cutive Mono',
                                            fontWeight: 'bold', 
                                            fontSize: '30px', 
                                            lineHeight: 1, 
                                            backgroundColor: props.darkMode ? '#000' : '#FFF', 
                                            opacity: 0.6, 
                                            display: 'inline-block',
                                            borderRadius: '30px',
                                            padding: 0.5
                                        }}>{props.logguedInUser.creditsPilates}</Typography>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        {/* Card name */}
                        <Grid item xs textAlign={'left'} sx={{marginTop: '10px'}}>
                            <Typography sx={{fontFamily: 'PangramRegular', lineHeight: 1, color: '#000', opacity: 0.6}}>Nombre</Typography>
                            <Typography 
                                sx={{
                                    fontFamily: 'Cutive Mono', 
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    color: '#000',
                                    opacity: 0.6,
                                    lineHeight: 1.5
                                }}>{props.logguedInUser.firstName} {props.logguedInUser.lastName}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                {/* Back side of card */}
                <Paper
                    className='flip-card-back'
                    sx={{
                        backgroundImage: `linear-gradient(to left, ${colors.fourthary}, ${colors.secondary})`, 
                        borderRadius: '15px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    }}
                >
                    <Grid container direction='column' sx={{p: 1}}>
                        {/* Card header */}
                        <Grid item xs sx={{height: '30px'}}>
                            <Grid container direction='row'>
                                <Grid item xs textAlign={'initial'}>
                                    <Typography sx={{fontFamily: 'Qairo', fontSize: '20px', color: '#000', opacity: 0.6}}>Créditos para invitados</Typography>
                                </Grid>
                                <Grid item xs textAlign={'end'}>
                                    <img height={'30px'} style={{filter: 'brightness(150%) contrast(101%)'}} src={AuraLogo} alt='Aura Logo' />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Card balance */}
                        <Grid item xs textAlign={'left'} sx={{marginTop: '40px'}}>
                            <Grid container direction='row' spacing={0.4}>
                                <Grid item textAlign={'center'}>
                                    <Typography 
                                        sx={{
                                            fontFamily: 'Cutive Mono',
                                            fontWeight: 'bold', 
                                            fontSize: '30px', 
                                            lineHeight: 1, 
                                            backgroundColor: props.darkMode ? '#000' : '#FFF', 
                                            opacity: 0.6, 
                                            display: 'inline-block',
                                            borderRadius: '30px',
                                            padding: 0.5,
                                            m: 0.5
                                        }}>
                                            {
                                                (props.logguedInUser.creditsForInvitees === '' || 
                                                props.logguedInUser.creditsForInvitees === undefined ||
                                                props.logguedInUser.creditsForInvitees === null ? '0' : props.logguedInUser.creditsForInvitees)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Card name */}
                        <Grid item xs textAlign={'left'} sx={{marginTop: '10px'}}>
                            <Typography 
                                sx={{
                                    fontFamily: 'PangramRegular', 
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    color: '#000',
                                    opacity: 0.6,
                                    lineHeight: 1.5
                                }}>Puedes utilizar estos créditos para invitar a un(a) amig@ a una clase contigo ☺️</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}